<template>
    <div class="section mt-2">
        <ul class="nav nav-tabs capsuled" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" data-bs-toggle="tab" href="#example1b" role="tab" @click="pageType('checkin')">
                    <ion-icon name="search-outline"></ion-icon>
                    Check-in
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#example2b" role="tab" @click="pageType('checkin_list')">
                    <ion-icon name="checkmark-done-outline"></ion-icon>
                    Check-in List
                </a>
            </li>
        </ul>
    </div>
    <div class="section inset mt-1 mb-1"
        v-if="(currentUser.user_level <= 3 || responseData.permissions.checkin) && !loading_list && this.currentAdminLevel != 7 && this.page_type == 'checkin'">
        <div class="accordion" id="accordionExample2">
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#accordion001" aria-expanded="false">
                        <ion-icon name="filter-outline" role="img" class="md hydrated"
                            aria-label="wallet outline"></ion-icon>
                        Filter
                    </button>
                </h2>
                <div id="accordion001" class="accordion-collapse collapse" data-bs-parent="#accordionExample2" style="">
                    <div class="accordion-body">
                        <div id="search" style="padding: 0px !important;">
                            <div class="form-group" v-if="currentUser.user_level == 1">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_district">District</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_district"
                                        name="filter_district" id="select4" @change="getZoneList($event.target.value)">
                                        <option value>---All---</option>
                                        <option v-for="district in district_list" v-bind:value="district.id"
                                            v-bind:key="district.id">{{
                                                district.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group" v-if="currentUser.user_level <= 2 || responseData.permissions.checkin">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_zone">Zone</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_zone"
                                        name="filter_zone" id="select4" @change="getUnitList($event.target.value)">
                                        <option value>---All---</option>
                                        <option v-for="zone in zone_list" v-bind:value="zone.id" v-bind:key="zone.id">{{
                                            zone.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group" v-if="currentUser.user_level <= 3 || responseData.permissions.checkin">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_unit">Unit</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_unit"
                                        name="filter_unit" id="select4">
                                        <option value>---All---</option>
                                        <option v-for="unit in unit_list" v-bind:value="unit.id" v-bind:key="unit.id">{{
                                            unit.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <!-- <div class="form-group" v-if="currentUser.user_level <= 4">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_reg_type">Registration Type</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_reg_type"
                                        name="filter_reg_type" id="select4">
                                        <option value>---All---</option>
                                        <option value="local">Local</option>
                                        <option value="campus">Campus</option>
                                        <option value="public">Public</option>
                                    </select>
                                </div>
                            </div> -->
                            <div class="form-group" v-if="currentUser.user_level <= 4">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_sex">Sex</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_sex"
                                        name="filter_sex" id="select4">
                                        <option value>---All---</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group" v-if="currentUser.user_level <= 4">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_payment">Payment Status</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_payment"
                                        name="filter_payment" id="select4">
                                        <option value>---All---</option>
                                        <option value="Paid">Paid</option>
                                        <option value="Not Paid">Not Paid</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group" v-if="currentUser.user_level <= 4">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_professional">Professional or Not</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_professional"
                                        name="filter_professional" id="select4">
                                        <option value>---All---</option>
                                        <option value="Y">Professional</option>
                                        <option value="N">Not Professional</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <button type="button" class="btn btn-danger btn-sm me-1 mb-1" style="width: 100%;"
                                        @click="clearFilter()">
                                        <ion-icon name="trash-bin-outline" role="img" class="md hydrated"
                                            aria-label="add outline"></ion-icon>
                                        Clear
                                    </button>
                                </div>
                                <div class="col-6">
                                    <button type="button" class="btn btn-primary btn-sm me-1 mb-1" style="width: 100%;"
                                        @click="getCheckinList(null, null, 'local')">
                                        <ion-icon name="search-outline" role="img" class="md hydrated"
                                            aria-label="add outline"></ion-icon>
                                        Filter
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section inset mt-1">
        <div id="search" style="padding: 0px !important;">
            <form class="search-form">
                <div class="row">
                    <div class="input-group">
                        <label class="block"><input type="radio" name="search_mode" value="mobile"
                                v-model="search_mode">Mobile</label>
                        &nbsp;&nbsp;
                        <label class="block"><input type="radio" name="search_mode" value="reg_no" v-model="search_mode">Reg
                            No</label>&nbsp;&nbsp;
                        <label class="block"><input type="radio" name="search_mode" value="name"
                                v-model="search_mode">Name</label>
                    </div>
                    <div class="input-group form-group" style="width:65%;">
                        <input :type="search_mode == 'name' ? 'text' : 'number'" placeholder="Search..." class="form-control"
                            focus v-model="search_qry" style="border-radius: 10px;" />
                    </div>
                    <div class="form-group pl-0" style="width:10% !important;" v-if="this.page_type == 'search'">
                        <button type="button" :disabled="search_qry ? false : true" class="btn btn-outline-success me-1"
                            @click="getMembersList">
                            <ion-icon name="search-outline" role="img" class="md hydrated"
                                aria-label="newspaper outline"></ion-icon>Search
                        </button>
                    </div>
                    <div class="form-group pl-0" style="width:10% !important;" v-else>
                        <button type="button" class="btn btn-outline-success me-1" @click="getCheckinList">
                            <ion-icon name="search-outline" role="img" class="md hydrated"
                                aria-label="newspaper outline"></ion-icon>Search
                        </button>
                    </div>
                    <!-- <div class="form-group pl-1" style="width:10% !important;">
                        <button type="button" class="btn btn-icon btn-outline-primary me-1" @click="getCheckinList">
                            <ion-icon name="list-outline" role="img" class="md hydrated"
                                aria-label="newspaper outline"></ion-icon>
                        </button>
                    </div> -->
                </div>
            </form>
        </div>
    </div>

    <div class="section text-center pt-1" v-if="loading_list">
        <div class="spinner-border text-primary" role="status"></div>
    </div>

    <!-- AddAdminModel -->
    <div class="modal fade action-sheet" id="addAdminModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-if="!add_admin">Edit</h5>
                    <h5 class="modal-title" v-if="add_admin">Add New</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="addAdmin" :validation-schema="schema">
                            <!-- <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="stay"><span>നിങ്ങളുടെ മണ്ഡലത്തിലെ വിദ്യാർഥിയാണോ</span><span
                                            class="text-danger">*</span></label>
                                    <select as="select" class="form-control custom-select" v-model="isYourZonal"
                                        name="isYourZonal" id="select4" @change="changeIsZonal($event.target.value)">
                                        <option value="Y">അതെ</option>
                                        <option value="N">അല്ല</option>
                                    </select>
                                </div>
                            </div> -->

                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="name">Name <span
                                            class="text-danger">*</span></label>
                                    <Field type="text" class="form-control" name="name" placeholder="Name" v-model="name"
                                        required />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="name" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="currentUser.user_level == 1">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="district">District <span
                                            class="text-danger">*</span></label>
                                    <Field as="select" class="form-control custom-select" v-model="district" name="district"
                                        id="select4" @change="getZoneList($event.target.value)">
                                        <option value="" selected>---Select---</option>
                                        <option v-for="district in district_list" v-bind:key="district.id"
                                            :value="district.id">
                                            {{ district.name }}</option>
                                        <option value="others">Other</option>
                                    </Field>
                                </div>
                                <ErrorMessage name="district" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="district == 'others'">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="other_native_district">Enter district</label>
                                    <Field type="text" class="form-control" name="other_native_district" placeholder=""
                                        v-model="other_native_district" />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="other_native_district" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="currentUser.user_level <= 2 && district <= 23">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="zone">Zone <span class="mal-text"></span></label>
                                    <Field as="select" class="form-control custom-select" v-model="zone" name="zone"
                                        id="select4" @change="getUnitList($event.target.value)">
                                        <option value="" selected>---Select---</option>
                                        <option v-for="zone in zone_list" v-bind:key="zone.id" :value="zone.id">
                                            {{ zone.name }}</option>
                                    </Field>
                                </div>
                                <ErrorMessage name="zone" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="currentUser.user_level <= 3 && district <= 23">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="unit">Unit <span class="mal-text"></span></label>
                                    <Field as="select" class="form-control custom-select" v-model="unit" name="unit"
                                        id="select4">
                                        <option value="" selected>---Select---</option>
                                        <option v-for="unit in unit_list" v-bind:key="unit.id" :value="unit.id">
                                            {{ unit.name }}</option>

                                    </Field>
                                </div>
                                <ErrorMessage name="unit" class="error-feedback" />
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="label text-primary" for="gender">Sex <span
                                                    class="text-danger">*</span></label>
                                            <Field as="select" class="form-control custom-select" name="gender" id="gender"
                                                v-model="gander" required>
                                                <option value="" selected>Select Sex</option>
                                                <option value="M">Male</option>
                                                <option value="F">Female</option>
                                            </Field>
                                        </div>
                                        <ErrorMessage name="gender" class="error-feedback" />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="mobile">Mobile <span
                                            class="text-danger">*</span></label>
                                    <Field type="text" class="form-control" id="mobile" name="mobile" placeholder="Mobile"
                                        v-model="mobile"
                                        oninput="javascript: if (this.value.length > 13) this.value = this.value.slice(0, 13);" />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="mobile" class="error-feedback" />
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="place">Place</label>
                                    <Field type="text" class="form-control" name="place" placeholder="Place"
                                        v-model="place" />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="place" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="currentAdminLevel != 7">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="campus_district">Campus District <span
                                            class="text-danger">*</span></label>
                                    <Field as="select" class="form-control custom-select" id="campus_district"
                                        name="campus_district" v-model="campus_district" @change="getCollegeList">
                                        <option value="" selected>---Select---</option>
                                        <option v-for="district in campus_district_list" v-bind:key="district.id"
                                            v-bind:value="district.item_value">
                                            {{ district.name }}
                                        </option>
                                    </Field>
                                </div>
                                <ErrorMessage name="campus_district" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="campus_district == 'others'">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="other_district">Enter Place Name</label>
                                    <Field type="text" class="form-control" name="other_district" placeholder=""
                                        v-model="other_district" />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="other_district" class="error-feedback" />
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="course_type">Course Type <span
                                            class="text-danger">*</span></label>
                                    <Field as="select" class="form-control custom-select" id="course_type"
                                        name="course_type" v-model="course_type"
                                        @change="changeCourseType($event.target.value);">
                                        <option value="" selected>---Select---</option>
                                        <option v-for="course_type in course_type_list" v-bind:key="course_type.id"
                                            v-bind:value="course_type.id">
                                            {{ course_type.name }}
                                        </option>
                                    </Field>
                                </div>
                                <ErrorMessage name="course_type" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="currentAdminLevel != 7">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="campus">College <span
                                            class="text-danger">*</span></label>
                                    <Field as="select" class="form-control custom-select" v-model="campus" name="campus"
                                        id="select4">
                                        <option value="" selected>---Select---</option>
                                        <option v-for="campus in campus_list" v-bind:key="campus.id"
                                            v-bind:value="campus.id">
                                            {{ campus.name }}
                                        </option>
                                        <option value="others">Others</option>
                                    </Field>
                                </div>
                                <ErrorMessage name="campus" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="campus == 'others'">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="other_college">Enter College Name</label>
                                    <Field type="text" class="form-control" name="other_college" placeholder=""
                                        v-model="other_college" />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="other_college" class="error-feedback" />
                            </div>
                            <div class="form-group basic">

                                <div class="input-wrapper">
                                    <label class="label text-primary" for="course">Course <span
                                            class="text-danger">*</span></label>
                                    <Field as="select" class="form-control custom-select" v-model="course" name="course"
                                        id="select4">
                                        <option value="" selected>---Select---</option>
                                        <option v-for="course in course_list" v-bind:key="course.id"
                                            v-bind:value="course.id">
                                            {{ course.name }}
                                        </option>
                                        <option value="others">Others</option>
                                    </Field>
                                </div>
                                <ErrorMessage name="course" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="course == 'others'">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="other_course">Enter Course Name</label>
                                    <Field type="text" class="form-control" name="other_course" placeholder=""
                                        v-model="other_course" />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="other_course" class="error-feedback" />
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="year_of_study">Year of Study<span
                                            class="text-danger">*</span></label>
                                    <Field as="select" class="form-control custom-select" id="year_of_study"
                                        name="year_of_study" v-model="year_of_study">
                                        <option value="" selected>---Select---</option>
                                        <option v-for="year_of_study in year_of_study_list" v-bind:key="year_of_study.id"
                                            v-bind:value="year_of_study.id">
                                            {{ year_of_study.name }}
                                        </option>
                                    </Field>
                                </div>
                                <ErrorMessage name="course_type" class="error-feedback" />
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="year_of_completion">Year of course
                                        completion<span class="text-danger">*</span></label>
                                    <Field as="select" class="form-control custom-select" id="year_of_completion"
                                        name="year_of_completion" v-model="year_of_completion">
                                        <option value="" selected>---Select---</option>
                                        <option v-for="year_of_completion in year_of_completion_list"
                                            v-bind:key="year_of_completion.id" v-bind:value="year_of_completion.id">
                                            {{ year_of_completion.name }}
                                        </option>
                                    </Field>
                                </div>
                                <ErrorMessage name="course_type" class="error-feedback" />
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="stay"><span>Payment Status</span><span
                                            class="text-danger">*</span></label>
                                    <select as="select" class="form-control custom-select" v-model="payment_status"
                                        name="payment_status" id="select4">
                                        <option value="Paid">Paid</option>
                                        <option value="Not Paid">Not Paid</option>
                                    </select>
                                </div>
                            </div>


                            <div class="form-group" v-if="message">
                                <div v-if="message" class="error-feedback">
                                    {{ message }}
                                </div>
                            </div>

                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading"
                                    v-if="permissions.update">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                                <button type="button" class="btn btn-primary btn-block btn-lg" v-if="!permissions.update"
                                    @click="closeAddAdminModel">Close</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade action-sheet" id="confirmationModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirmation</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="confirmation" :validation-schema="schema">
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="stay"><span>Confirmation Status</span><span
                                            class="text-danger">*</span></label>
                                    <select as="select" :disabled="confirmation_disabled" class="form-control custom-select"
                                        v-model="confirmation_status" name="confirmation_status" id="select4">
                                        <option value="P">Professional</option>
                                        <option value="NP">Not a Professional</option>
                                    </select>
                                </div>
                            </div>


                            <div class="form-group" v-if="message">
                                <div v-if="message" class="error-feedback">
                                    {{ message }}
                                </div>
                            </div>

                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg"
                                    :disabled="loading && confirmation_disabled" v-if="permissions.update">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                                <button type="button" class="btn btn-primary btn-block btn-lg" v-if="!permissions.update"
                                    @click="closeAddAdminModel">Close</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade action-sheet" id="checkinModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Check-in</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="checkin" :validation-schema="schema">
                            <div class="form-group boxed">
                                <label class="label text-primary" for="stay">Candidate</label>
                                <div class="input-group d-flex align-items-center">
                                    <Field type="text" class="form-control" name="candidate_name" disabled placeholder=""
                                        v-model="candidate_name" />
                                    <div class="form-check form-check-inline" style="border: solid 1px red;
                                    padding: 7px;
                                    border-radius: 0px 10px 10px 0px;">
                                        <input class="form-check-input" type="checkbox" id="candidateCheckin" value="Y"
                                            v-model="candidate_checkin" name="candidate_checkin"
                                            :checked="candidate_checkin == 'Y' ? 'checked' : false">
                                        <label class="form-check-label" for="candidateCheckin">Checkin</label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group boxed">
                                <label class="label text-primary" for="stay">Spouse</label>
                                <div class="input-group d-flex align-items-center">
                                    <Field type="text" class="form-control" name="spouse_name" placeholder=""
                                        v-model="spouse_name" />
                                    <div class="form-check form-check-inline" style="border: solid 1px red;
                                        padding: 7px;
                                        border-radius: 0px 10px 10px 0px;">
                                        <input class="form-check-input" type="checkbox" id="spouseCheckin"
                                            :disabled="!spouse_name" name="spouse_checkin" v-model="spouse_checkin"
                                            :checked="spouse_checkin == 'Y'" value="Y">
                                        <label class="form-check-label" for="spouseCheckin">Checkin</label>
                                    </div>
                                </div>
                                <!-- <input type="text" v-if="!spouse_name" value="No Data." class="form-control text-danger"
                                    placeholder="" disabled /> -->

                            </div>
                            <div class="form-group boxed">
                                <label class="label text-primary" for="stay">Childrens</label>
                                <div class="input-group d-flex align-items-center mt-1" v-for="item in children"
                                    :key="item.id">
                                    <input type="text" :value="item.name + '(' + item.age + '-'
                                        + (item.age >= 3 && item.age <= 5 ? 'SWT BDS' : '')
                                        + (item.age >= 6 && item.age <= 9 ? 'BUTFLY' : '')
                                        + (item.age >= 10 && item.age <= 12 ? 'LTLE WNG' : '')
                                        + (item.age >= 13 && item.age <= 19 ? 'TEEN' : '') +
                                        ')'" class="form-control" placeholder="" disabled />

                                    <div class="form-check form-check-inline"
                                        style="border: solid 1px red;padding: 7px;border-radius: 0px 10px 10px 0px;">
                                        <Field class="form-check-input" name="children_checkin" type="checkbox"
                                            :id="'children' + item.id" :value="item.id" v-model="childrens_mod" />
                                        <label class="form-check-label" :for="'children' + item.id"> Checkin
                                        </label>
                                    </div>
                                </div>
                                <input type="text" v-if="!children.length" value="No Data." class="form-control text-danger"
                                    placeholder="" disabled />
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="additional_participants">Additional
                                        Participants</label>
                                    <Field type="number" class="form-control" name="additional_participants" placeholder=""
                                        v-model="additional_participants" />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="additional_participants" class="error-feedback" />
                            </div>

                            <!-- <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="stay"><span>Check-in Status</span><span
                                            class="text-danger">*</span></label>
                                    <select as="select" :disabled="checkin_disabled" class="form-control custom-select"
                                        v-model="checkin_status" name="checkin_status" id="select4">
                                        <option value="Y">Check-in</option>
                                        <option value="N">Not Check-in</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group basic" v-if="checkin_status == 'Y'">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="spouse_checkin_status"><span>Spouse Check-in
                                            Status</span><span class="text-danger">*</span></label>
                                    <select as="select" :disabled="checkin_disabled" class="form-control custom-select"
                                        v-model="spouse_checkin_status" name="spouse_checkin_status" id="select4">
                                        <option value="Y">Check-in</option>
                                        <option value="N">Not Check-in</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group basic" v-if="checkin_status == 'Y'">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="childrens_0_3_checkin">Childrens 0-3</label>
                                    <Field type="number" class="form-control" name="childrens_0_3_checkin" placeholder=""
                                        v-model="childrens_0_3_checkin" />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="childrens_0_3_checkin" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="checkin_status == 'Y'">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="childrens_4_8_checkin">Childrens 4-8</label>
                                    <Field type="number" class="form-control" name="childrens_4_8_checkin" placeholder=""
                                        v-model="childrens_4_8_checkin" />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="childrens_4_8_checkin" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="checkin_status == 'Y'">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="childrens_9_14_checkin">Childrens 9-14</label>
                                    <Field type="number" class="form-control" name="childrens_9_14_checkin" placeholder=""
                                        v-model="childrens_9_14_checkin" />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="childrens_9_14_checkin" class="error-feedback" />
                            </div> -->
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="stay"><span>Payment Status</span><span
                                            class="text-danger">*</span></label>
                                    <select as="select" :disabled="checkin_disabled || fees_update_disabled"
                                        class="form-control custom-select" v-model="payment_status" name="payment_status"
                                        id="select4">
                                        <option value="Paid">Paid</option>
                                        <option value="Not Paid">Not Paid</option>
                                    </select>
                                </div>
                            </div>
                            <div class="card text-white bg-secondary mb-1" v-if="remarks">
                                <div class="card-header">Remarks</div>
                                <div class="card-body">
                                    <p class="card-text" v-html="remarks"></p>
                                </div>
                            </div>

                            <div class="form-group" v-if="message">
                                <div v-if="message" class="error-feedback">
                                    {{ message }}
                                </div>
                            </div>

                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg"
                                    :disabled="loading && checkin_disabled" v-if="permissions.update">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                                <button type="button" class="btn btn-primary btn-block btn-lg" v-if="!permissions.update"
                                    @click="closeAddAdminModel">Close</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="listed-detail mt-0" v-if="!loading_list && member_lists.total <= 0 && page_type == 'search' && search_qry">
        <h4 class="text-center mt-0">No Results... <br>
            <!-- For New registration  -->
            <!-- <a href="javascript:void(0);" @click="adminAdd">Click here</a> -->
        </h4>

    </div>

    <!-- <div class="section" v-if="!loading_list">
        <span><a :href="'https://batch.msoftit.com/reports/pdf/reportZonalCouncil.php?zone_id=' + unit.id + '&wing=6&mobile='+currentUser.phone"
                :download="'Girls Zonal Council'">
                <ion-icon name="download-outline"></ion-icon> Download PDF
            </a></span>
    </div> -->
    <div class="section pt-0 mt-0"
        v-if="!loading_list && registrations_list && member_lists.total > 0 && page_type == 'search'">
        <div class="listed-detail mt-0 page-title">
            <h3 class="text-center mt-1 mal-text custom-title">Search Results</h3>
        </div>
    </div>
    <div class="section" v-if="!loading_list && page_type == 'checkin'">
        <span><a :href="'https://guide.msoftit.com/export/event-export-data?user_level=' + currentUser.user_level + '&district_id='
            + currentUser.district_id + '&zone_id='
            + currentUser.zone_id + '&unit_id='
            + currentUser.unit_id + '&campus_id='
            + currentUser.campus_id + '&admin_level='
            + currentAdminLevel + '&type='
            + 'checkin' + '&filter_district='
            + filter_district + '&filter_zone='
            + filter_zone + '&filter_sex='
            + filter_sex + '&filter_unit='
            + filter_unit + '&filter_campus_district='
            + filter_campus_district + '&filter_campus='
            + filter_campus + '&filter_reg_type='
            + filter_reg_type + '&filter_confirm='
            + filter_confirm + '&event_type='
            + $route.params.slug + '&filter_professional='
            + filter_professional + '&search_qry='
            + search_qry + '&event_id='
            + this.$route.params.event_id +''

            " :download="'Profcon Registrations'">
                <ion-icon name="download-outline"></ion-icon> Download Excel
            </a></span>
    </div>
    <ul class="listview image-listview inset text mt-1"
        v-if="!loading_list && member_lists.total > 0 && registrations_list && page_type == 'search'">
        <li v-for="(member_list, index) in member_lists.data" v-bind:key="member_list.id"
            :style="{ 'background-color': member_list.confirmation_status == 'NP' ? '#ffb3b3' : (member_list.registered_from == 'campus' ? '#ffffb3' : member_list.registered_from == 'public' ? '#b3ffec' : '#f2f2f2') }">
            <a href="javascript:void(0)" class="item">
                <span class="sl-no">{{ (member_lists.from + index) }}</span>
                <div class="avatar-section">
                    <a href="#">
                        <img src="assets/img/sample/avatar/avatar1.png" alt="avatar" class="image">
                        <span class="button-payment payment-verified" v-if="member_list.payment_status == 'Paid'">
                            <ion-icon name="card-outline"></ion-icon>
                        </span>
                        <span class="button verified" v-if="member_list.confirmation_status == 'Y'">
                            <ion-icon name="checkmark-outline"></ion-icon>
                        </span>

                        <span class="button not-verified" v-if="member_list.confirmation_status == 'N'">
                            <ion-icon name="close-outline"></ion-icon>
                        </span>
                    </a>
                </div>
                <div class="in">
                    <div class="min-width-50">
                        <header class="text-danger" v-if="member_list.reg_no">Reg No: {{ member_list.reg_no }}</header>
                        <b> {{ member_list.name }} </b>
                        <footer class="text-primary">
                            <table>
                                <tr>
                                    <th>Mobile: <span class="text-dark">{{ member_list.mobile }} </span></th>
                                </tr>
                                <tr>
                                    <th>District: <span class="text-dark">{{ member_list.district ?
                                        member_list.district.name : '' }} {{ member_list.district_name_other ?
        member_list.district_name_other : '' }}</span>
                                    </th>
                                </tr>
                                <tr>
                                    <th>Zone: <span class="text-dark">{{ member_list.zone ? member_list.zone.name : '' }}
                                        </span></th>
                                </tr>
                                <tr>
                                    <th>Unit: <span class="text-dark">{{ member_list.unit ? member_list.unit.name : '' }}
                                        </span></th>
                                </tr>
                                <tr v-if="responseData.event.form.school">
                                    <th>School: <span class="text-dark">{{ member_list.school ? member_list.school :
                                        '' }}</span></th>
                                </tr>
                                <tr v-if="responseData.event.form.school">
                                    <th>Class: <span class="text-dark"> {{ member_list.class ? member_list.class : '' }} {{
                                        member_list.class_other ? member_list.class_other : '' }}</span></th>
                                </tr>
                                <tr v-if="responseData.event.form.campus">
                                    <th>Campus: <span class="text-dark">{{ member_list.campus ? member_list.campus.name : ''
                                    }} {{ member_list.campus_other ? member_list.campus_other : ''
}} </span></th>
                                </tr>
                                <tr v-if="responseData.event.form.course">
                                    <th>Course: <span class="text-dark">{{ member_list.course ? member_list.course.name : ''
                                    }} {{ member_list.course_other ? member_list.course_other : ''
}} </span></th>
                                </tr>
                                <tr v-if="responseData.event.form.job_type">
                                    <th>Job Level: <span class="text-dark">
                                            {{ member_list.job_type }}
                                        </span>
                                    </th>
                                </tr>
                                <tr v-if="responseData.event.form.job_instituion_level">
                                    <th>Institution Level: <span class="text-dark">
                                            {{ member_list.institution_level }} {{ member_list.institution_level_other }}
                                        </span>
                                    </th>
                                </tr>
                                <tr v-if="responseData.event.form.job_company">
                                    <th>Institution: <span class="text-dark">
                                            {{ member_list.company }} {{ member_list.company_text }}
                                        </span>
                                    </th>
                                </tr>
                                <tr v-if="responseData.event.form.job_designation">
                                    <th>Designation: <span class="text-dark">
                                            {{ member_list.designation }}
                                        </span>
                                    </th>
                                </tr>
                                <tr>
                                    <th>Registered From: <span class="text-dark">{{ member_list.registered_from }} </span>
                                    </th>
                                </tr>
                                <tr v-if="member_list.registered">
                                    <th>Registered By: <span class="text-dark">{{ member_list.registered ?
                                        member_list.registered.name : '' }}
                                        </span></th>
                                </tr>
                                <tr v-if="member_list.confirmation_from">
                                    <th>Confirmed From: <span class="text-dark">{{ member_list.confirmation_from }} </span>
                                    </th>
                                </tr>
                                <tr v-if="responseData.event.form.professional">
                                    <th v-if="member_list.confirmation_status == 'NP'" class="text-danger">Not a
                                        Professional: <span>State Admin</span></th>
                                    <th v-else>Confirmed By: <span class="text-dark">{{ member_list.confirmed_by_name ?
                                        member_list.confirmed_by_name
                                        : '' }} </span></th>
                                </tr>
                                <tr v-if="member_list.remarks">
                                    <th>Remarks: <span class="text-dark">{{ member_list.remarks ? member_list.remarks
                                        : '' }} </span></th>
                                </tr>
                                <tr v-if="member_list.checkin_status" style="border-top: solid 1px;
                                border-left: solid 1px;
                                border-right: solid 1px;">
                                    <th>Check-in Status: <span class="text-success">{{ member_list.checkin_status == 'Y' ?
                                        'Checkin'
                                        : '' }} </span></th>
                                </tr>
                                <tr v-if="member_list.checkin_by_name" style="border-left: solid 1px;
                                border-right: solid 1px;"
                                    :v-bind:style="!member_list.bus ? 'border-bottom: solid 1px' : ''">
                                    <th>Check-in By: <span class="text-dark">{{ member_list.checkin_by_name ?
                                        member_list.checkin_by_name
                                        : '' }} </span></th>
                                </tr>
                                <tr v-if="member_list.bus" style="border-bottom: solid 1px;
                                border-left: solid 1px;
                                border-right: solid 1px;">
                                    <th>Check-in from: <span class="text-danger">{{ member_list.bus ?
                                        member_list.bus.bus_code
                                        : '' }} </span></th>
                                </tr>
                            </table>
                        </footer>
                    </div>
                    <div class="card-button dropdown">
                        <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
                            :data-bs-target="'#actionSheetInset' + member_list.id" @click="showActionModel">
                            <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                        </button>
                    </div>

                    <!-- Default Action Sheet Inset -->
                    <div class="modal fade action-sheet" :id="'actionSheetInset' + member_list.id" tabindex="-1"
                        role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Action</h5>
                                </div>
                                <div class="modal-body">
                                    <ul class="action-button-list">
                                        <li v-if="responseData.permissions.checkin">
                                            <a href="javascript:void(0)" @click="checkinView(member_list)"
                                                class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="business-outline"></ion-icon> CheckIn
                                                </span>
                                            </a>
                                        </li>
                                        <!-- <li
                                            v-if="responseData.permissions.confirmation && (member_list.confirmation_status != 'NP' || currentUser.user_level == 1)">
                                            <a href="javascript:void(0)" @click="confirmationView(member_list)"
                                                class="btn btn-list text-success" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="checkmark-done-outline"></ion-icon> Confirmation
                                                </span>
                                            </a>
                                        </li> -->
                                        <li>
                                            <a :href="'tel:' + member_list.mobile" class="btn btn-list">
                                                <span>
                                                    <ion-icon name="call-outline"></ion-icon> Call
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="responseData.permissions.edit">
                                            <a href="javascript:void(0)" @click="adminView(member_list)"
                                                class="btn btn-list text-primary" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="pencil-outline"></ion-icon> Edit
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="responseData.permissions.checkin">
                                            <a href="javascript:void(0)" @click="sendEditLink(member_list)"
                                                class="btn btn-list text-warning" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="mail-open-outline"></ion-icon> Send Edit Link
                                                </span>
                                            </a>
                                        </li>
                                        <!-- <li>
                                            <a href="javascript:void(0)" @click="adminDeleteConfirmation(member_list)"
                                                class="btn btn-list text-warning" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="trash-outline"></ion-icon> Remove
                                                </span>
                                            </a>
                                        </li> -->
                                        <li class="action-divider"></li>
                                        <li>
                                            <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="close-outline"></ion-icon> Cancel
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- * Default Action Sheet Inset -->
                </div>
            </a>
        </li>
    </ul>
    <div class="section mt-2" v-if="!loading_list && page_type == 'search'">
        <vue-awesome-paginate v-if="member_lists.total > 0" :total-items="member_lists.total"
            :items-per-page="member_lists.per_page" :max-pages-shown="3" v-model="member_lists.current_page"
            :on-click="getMembersList" :show-ending-buttons="true" :show-breakpoint-buttons="false" />
    </div>

    <!-- CHECKIN LIST -->
    <div class="section pt-0 mt-0" v-if="!loading_list && checkin_lists.total > 0 && page_type == 'checkin'">
        <div class="listed-detail mt-0 page-title">
            <h3 class="text-center mt-1 mal-text custom-title">Checkin List</h3>
        </div>
    </div>
    <ul class="listview image-listview inset text mt-1"
        v-if="!loading_list && checkin_lists.total > 0 && page_type == 'checkin'">
        <li v-for="(checkin_list, index) in checkin_lists.data" v-bind:key="checkin_list.id"
            :style="{ 'background-color': checkin_list.confirmation_status == 'NP' ? '#ffb3b3' : (checkin_list.registered_from == 'campus' ? '#ffffb3' : checkin_list.registered_from == 'public' ? '#b3ffec' : '#f2f2f2') }">
            <a href="javascript:void(0)" class="item">
                <span class="sl-no">{{ (checkin_lists.from + index) }}</span>
                <div class="avatar-section">
                    <a href="#">
                        <img src="assets/img/sample/avatar/avatar1.png" alt="avatar" class="image">
                        <span class="button-payment payment-verified" v-if="checkin_list.payment_status == 'Paid'">
                            <ion-icon name="card-outline"></ion-icon>
                        </span>
                        <span class="button verified" v-if="checkin_list.confirmation_status == 'Y'">
                            <ion-icon name="checkmark-outline"></ion-icon>
                        </span>

                        <span class="button not-verified" v-if="checkin_list.confirmation_status == 'N'">
                            <ion-icon name="close-outline"></ion-icon>
                        </span>
                    </a>
                </div>
                <div class="in">
                    <div class="min-width-50">
                        <header v-if="checkin_list.reg_no" class="text-danger">Reg No: {{ checkin_list.reg_no }}</header>
                        <b> {{ checkin_list.name }} </b>
                        <footer class="text-primary">
                            <!-- <table>
                                <tr>
                                    <th>Mobile: <span class="text-dark">{{ checkin_list.mobile }} </span></th>
                                </tr>
                                <tr>
                                    <th>District: <span class="text-dark">{{ checkin_list.district ?
                                        checkin_list.district.name : '' }} </span>
                                    </th>
                                </tr>
                                <tr>
                                    <th>Zone: <span class="text-dark">{{ checkin_list.zone ? checkin_list.zone.name : '' }}
                                        </span></th>
                                </tr>
                                <tr>
                                    <th>Unit: <span class="text-dark">{{ checkin_list.unit ? checkin_list.unit.name : '' }}
                                        </span></th>
                                </tr>
                                <tr>
                                    <th>Campus: <span class="text-dark">{{ checkin_list.campus ? checkin_list.campus.name :
                                        ''
                                    }} {{ checkin_list.campus_other ? checkin_list.campus_other : ''
}} </span></th>
                                </tr>
                                <tr>
                                    <th>Course: <span class="text-dark">{{ checkin_list.course ? checkin_list.course.name :
                                        ''
                                    }} {{ checkin_list.course_other ? checkin_list.course_other : ''
}} </span></th>
                                </tr>
                                <tr>
                                    <th>Registered From: <span class="text-dark">{{ checkin_list.registered_from }} </span>
                                    </th>
                                </tr>
                                <tr>
                                    <th>Registered By: <span class="text-dark">{{ checkin_list.registered ?
                                        checkin_list.registered.name : '' }}
                                        </span></th>
                                </tr>
                                <tr>
                                    <th v-if="checkin_list.confirmation_status == 'NP'" class="text-danger">Not a
                                        Professional: <span>State Admin</span></th>
                                    <th v-else>Confirmed By: <span class="text-dark">{{ checkin_list.confirmed_by_name ?
                                        checkin_list.confirmed_by_name
                                        : '' }} </span></th>
                                </tr>
                                <tr v-if="checkin_list.remarks">
                                    <th>Remarks: <span class="text-dark">{{ checkin_list.remarks ? checkin_list.remarks
                                        : '' }} </span></th>
                                </tr>
                                <tr v-if="checkin_list.checkin_status" style="border-top: solid 1px;
                                border-left: solid 1px;
                                border-right: solid 1px;">
                                    <th>Checkin Status: <span class="text-success">{{ checkin_list.checkin_status == 'Y' ?
                                        'Checkin'
                                        : '' }} </span></th>
                                </tr>
                                <tr v-if="checkin_list.checkin_by_name" style="border-left: solid 1px;
                                border-right: solid 1px;"
                                    :v-bind:style="!checkin_list.bus ? 'border-bottom: solid 1px' : ''">
                                    <th>Checkin By: <span class="text-dark">{{ checkin_list.checkin_by_name ?
                                        checkin_list.checkin_by_name
                                        : '' }} </span></th>
                                </tr>
                                <tr v-if="checkin_list.bus" style="border-bottom: solid 1px;
                                border-left: solid 1px;
                                border-right: solid 1px;">
                                    <th>Checkin from: <span class="text-danger">{{ checkin_list.bus ?
                                        checkin_list.bus.bus_code
                                        : '' }} </span></th>
                                </tr>
                            </table> -->
                            <table>
                                <tr>
                                    <th>Mobile: <span class="text-dark">{{ checkin_list.mobile }} </span></th>
                                </tr>
                                <tr>
                                    <th>District: <span class="text-dark">{{ checkin_list.district ?
                                        checkin_list.district.name : '' }} {{ checkin_list.district_name_other ?
        checkin_list.district_name_other : '' }}</span>
                                    </th>
                                </tr>
                                <tr>
                                    <th>Zone: <span class="text-dark">{{ checkin_list.zone ? checkin_list.zone.name : '' }}
                                        </span></th>
                                </tr>
                                <tr>
                                    <th>Unit: <span class="text-dark">{{ checkin_list.unit ? checkin_list.unit.name : '' }}
                                        </span></th>
                                </tr>
                                <tr v-if="responseData.event.form.school">
                                    <th>School: <span class="text-dark">{{ checkin_list.school ? checkin_list.school :
                                        '' }}</span></th>
                                </tr>
                                <tr v-if="responseData.event.form.school">
                                    <th>Class: <span class="text-dark"> {{ checkin_list.class ? checkin_list.class : '' }}
                                            {{
                                                checkin_list.class_other ? checkin_list.class_other : '' }}</span></th>
                                </tr>
                                <tr v-if="responseData.event.form.campus">
                                    <th>Campus: <span class="text-dark">{{ checkin_list.campus ? checkin_list.campus.name :
                                        ''
                                    }} {{ checkin_list.campus_other ? checkin_list.campus_other : ''
}} </span></th>
                                </tr>
                                <tr v-if="responseData.event.form.course">
                                    <th>Course: <span class="text-dark">{{ checkin_list.course ? checkin_list.course.name :
                                        ''
                                    }} {{ checkin_list.course_other ? checkin_list.course_other : ''
}} </span></th>
                                </tr>
                                <tr v-if="responseData.event.form.job_type">
                                    <th>Job Level: <span class="text-dark">
                                            {{ checkin_list.job_type }}
                                        </span>
                                    </th>
                                </tr>
                                <tr v-if="responseData.event.form.job_instituion_level">
                                    <th>Institution Level: <span class="text-dark">
                                            {{ checkin_list.institution_level }} {{ checkin_list.institution_level_other }}
                                        </span>
                                    </th>
                                </tr>
                                <tr v-if="responseData.event.form.job_company">
                                    <th>Institution: <span class="text-dark">
                                            {{ checkin_list.company }} {{ checkin_list.company_text }}
                                        </span>
                                    </th>
                                </tr>
                                <tr v-if="responseData.event.form.job_designation">
                                    <th>Designation: <span class="text-dark">
                                            {{ checkin_list.designation }}
                                        </span>
                                    </th>
                                </tr>
                                <tr>
                                    <th>Registered From: <span class="text-dark">{{ checkin_list.registered_from }} </span>
                                    </th>
                                </tr>
                                <tr v-if="checkin_list.registered">
                                    <th>Registered By: <span class="text-dark">{{ checkin_list.registered ?
                                        checkin_list.registered.name : '' }}
                                        </span></th>
                                </tr>
                                <tr v-if="checkin_list.confirmation_from">
                                    <th>Confirmed From: <span class="text-dark">{{ checkin_list.confirmation_from }} </span>
                                    </th>
                                </tr>
                                <tr v-if="responseData.event.form.professional">
                                    <th v-if="checkin_list.confirmation_status == 'NP'" class="text-danger">Not a
                                        Professional: <span>State Admin</span></th>
                                    <th v-else>Confirmed By: <span class="text-dark">{{ checkin_list.confirmed_by_name ?
                                        checkin_list.confirmed_by_name
                                        : '' }} </span></th>
                                </tr>
                                <tr v-if="checkin_list.remarks">
                                    <th>Remarks: <span class="text-dark">{{ checkin_list.remarks ? checkin_list.remarks
                                        : '' }} </span></th>
                                </tr>
                                <tr v-if="checkin_list.checkin_status" style="border-top: solid 1px;
                                border-left: solid 1px;
                                border-right: solid 1px;">
                                    <th>Check-in Status: <span class="text-success">{{ checkin_list.checkin_status == 'Y' ?
                                        'Checkin'
                                        : '' }} </span></th>
                                </tr>
                                <tr v-if="checkin_list.checkin_by_name" style="border-left: solid 1px;
                                border-right: solid 1px;"
                                    :v-bind:style="!checkin_list.bus ? 'border-bottom: solid 1px' : ''">
                                    <th>Check-in By: <span class="text-dark">{{ checkin_list.checkin_by_name ?
                                        checkin_list.checkin_by_name
                                        : '' }} </span></th>
                                </tr>
                                <tr v-if="checkin_list.bus" style="border-bottom: solid 1px;
                                border-left: solid 1px;
                                border-right: solid 1px;">
                                    <th>Check-in from: <span class="text-danger">{{ checkin_list.bus ?
                                        checkin_list.bus.bus_code
                                        : '' }} </span></th>
                                </tr>
                            </table>
                        </footer>
                    </div>
                    <div class="card-button dropdown">
                        <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
                            :data-bs-target="'#actionSheetInset' + checkin_list.id" @click="showActionModel">
                            <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                        </button>
                    </div>

                    <!-- Default Action Sheet Inset -->
                    <div class="modal fade action-sheet" :id="'actionSheetInset' + checkin_list.id" tabindex="-1"
                        role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Action</h5>
                                </div>
                                <div class="modal-body">
                                    <ul class="action-button-list">
                                        <li v-if="responseData.permissions.checkin">
                                            <a href="javascript:void(0)" @click="checkinView(checkin_list)"
                                                class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="business-outline"></ion-icon> Change CheckIn Status
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a :href="'tel:' + checkin_list.mobile" class="btn btn-list">
                                                <span>
                                                    <ion-icon name="call-outline"></ion-icon> Call
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="responseData.permissions.confirmation">
                                            <a href="javascript:void(0)" @click="confirmationView(checkin_list)"
                                                class="btn btn-list text-success" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="checkmark-done-outline"></ion-icon> Professional / Not
                                                </span>
                                            </a>
                                        </li>
                                        <!-- <li>
                                            <a href="javascript:void(0)" @click="adminView(checkin_list)"
                                                class="btn btn-list text-primary" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="pencil-outline"></ion-icon> Edit
                                                </span>
                                            </a>
                                        </li> -->
                                        <!-- <li>
                                            <a href="javascript:void(0)" @click="adminDeleteConfirmation(checkin_list)"
                                                class="btn btn-list text-warning" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="trash-outline"></ion-icon> Remove
                                                </span>
                                            </a>
                                        </li> -->
                                        <li class="action-divider"></li>
                                        <li>
                                            <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="close-outline"></ion-icon> Cancel
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- * Default Action Sheet Inset -->
                </div>
            </a>
        </li>
    </ul>
    <div class="section mt-2" v-if="!loading_list && page_type == 'checkin'">
        <vue-awesome-paginate v-if="checkin_lists.total > 0" :total-items="checkin_lists.total"
            :items-per-page="checkin_lists.per_page" :max-pages-shown="3" v-model="checkin_lists.current_page"
            :on-click="getCheckinList" :show-ending-buttons="true" :show-breakpoint-buttons="false" />
    </div>

    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import UserService from "../../services/user.service";
import $ from 'jquery';
import AlertModel from "./../layouts/AlertModel.vue";
import DialogModel from "./../layouts/DialogModel.vue";
// import * as yup from "yup";
export default {
    name: 'Admin List',
    components: {
        Form,
        Field,
        ErrorMessage,
        AlertModel,
        DialogModel
    },
    props: {
        unit_id: null
    },
    data() {
        //     const schema_non_member = yup.object().shape({
        //         name: yup.string().when("isMember", {
        //     is: 'N',
        //     then: yup.string().required('ഫിൽ ചെയ്യുക!')
        //   }),
        //         age: yup.string().required('ഫിൽ ചെയ്യുക!'),
        //         gender: yup.string().required('സെലക്റ്റ് ചെയ്യുക!'),
        //     });
        return {
            loading: false,
            loading_list: false,
            registrations_list: false,
            page_type: 'search',
            fees_update_disabled: false,
            message: "",
            country_code: '91',
            country_code_whatsapp: '91',
            mobile_number: '',
            whatsapp_number: '',
            wing: '',
            // schema_non_member,
            stay: '',
            user_level: "",
            sub_levels: [],
            member_lists: [],
            checkin_lists: [],
            member_list: [],
            member: [],
            member_edit: true,
            orgnal_member_lists: [],
            orgnal_checkin_lists: [],
            trash_list: [],
            editable_id: '',
            editable: false,
            permissions: [],
            add_admin: false,
            abroad_fields: false,
            pin_number: '',
            attendance: 'A',
            attendance_remarks: '',
            search_qty: '',
            member_id: '',
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            attendance_present: [],
            attendance_absent: [],
            filter_wing: '',
            isMember: '',
            isSubscriber: '',
            receipt_no: '',
            subscription_no: '',
            isEdit: false,
            sub_level_name: '',
            responseData: {
                event: [],
                questians: [],
                permissions: []
            },
            questians_responses: [],

            isYourZonal: '',
            district_list: [],
            campus_district_list: [],
            zone_list: [],
            unit_list: [],
            district: '',
            zone: '',
            unit: '',

            campus_district: '',
            other_district: '',
            campus_list: [],
            campus: '',
            other_college: '',
            course_list: [],
            course: '',
            other_course: '',
            course_type_list: [],
            course_type: '',

            filter_district: '',
            filter_zone: '',
            filter_unit: '',
            year_of_completion: '',
            year_of_study: '',
            currentAdminLevel: '',
            filter_campus: '',
            filter_campus_district: '',
            filter_reg_type: '',
            confirmation_status: '',
            confirmation_disabled: false,
            checkin_disabled: false,
            payment_status: '',
            filter_confirm: '',
            filter_payment: '',
            filter_sex: '',
            spouse_checkin_status: '',
            childrens_0_3_checkin: '',
            childrens_4_8_checkin: '',
            childrens_9_14_checkin: '',
            confirmation_from: '',
            checkin_status: 'Y',
            filter_professional: '',
            search_qry: '',
            search_mode: 'mobile',
            selected_candidate: [],
            children: [],
            child_checkins: [],
            spouse_checkin: '',
            candidate_checkin: '',
            childrens_mod: [],
            remarks : '',
            year_of_completion_list: [
                {
                    id: 2023,
                    name: '2023'
                },
                {
                    id: 2024,
                    name: '2024'
                },
                {
                    id: 2025,
                    name: '2025'
                },
                {
                    id: 2026,
                    name: '2026'
                },
                {
                    id: 2027,
                    name: '2027'
                },
                {
                    id: 2028,
                    name: '2028'
                },
                {
                    id: 2029,
                    name: '2029'
                }
            ],
            year_of_study_list: [
                {
                    id: 1,
                    name: '1'
                },
                {
                    id: 2,
                    name: '2'
                },
                {
                    id: 3,
                    name: '3'
                },
                {
                    id: 4,
                    name: '4'
                },
                {
                    id: 5,
                    name: '5'
                },
                {
                    id: 6,
                    name: '6'
                },
            ]

        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        minusToZero() {
            let value = this.unit.nerpatham_target - this.member_lists.total;
            if (value <= 0) {
                return '0';
            } else {
                return value;
            }
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.currentAdminLevel = localStorage.getItem('currentAdminLevel');
            // this.getMembersList(1, this.currentAdminLevel);
            this.$root.updateParent('Checkin');
        }
        this.getComboboxValues('COUNTRY_CODE');
        this.getComboboxValues("PROFCON_COURSE_TYPE");
        this.getComboboxValues("DISTRICT_KERALA");
        this.getDistrictList();
        this.getZoneList(this.currentUser.district_id);
        this.isYourZonal = 'Y';
        this.changeIsZonal('Y');
        if (this.currentAdminLevel <= 4) {
            this.confirmation_from = 'local';
        } else {
            this.confirmation_from = 'campus';
        }

        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        getComboboxValues(code, id = null) {
            var data = {
                code: code,
                type_id: id
            }
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case 'COUNTRY_CODE':
                            this.country_code_list = response.data.data.list.options;
                            break;
                        case "DISTRICT_KERALA":
                            this.campus_district_list = response.data.data.list.options;
                            break;
                        case "PROFCON_COURSES":
                            this.course_list = response.data.data.list.options;
                            break;
                        case "PROFCON_COURSE_TYPE":
                            this.course_type_list = response.data.data.list.options;
                            break;

                        default:
                            break;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getTextList(event, type) {
            this.message = '';
            let data = {
                q: event,
                type: type,
                filter: 'inside',
                unit_id: this.$route.params.id,
                user_level: this.user_level
            }
            UserService.authPostRequest('get-typehead-values', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.member_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        adminView(admin) {

            $('#addAdminModel').modal('show');
            this.message = '';
            this.isEdit = true;
            if (admin.zone_id == this.currentUser.zone_id) {
                this.isYourZonal = 'Y';
                this.getZoneList(this.currentUser.district_id);
                this.getUnitList(this.currentUser.zone_id);
            } else if (admin.district_id != 0) {
                this.isYourZonal = 'N';
                this.getDistrictList();
                if (admin.zone_id) {
                    this.getUnitList(admin.zone_id);
                }
                this.getZoneList(admin.district_id);
            }

            this.getComboboxValues("DISTRICT_KERALA");
            // if (admin.district_id) {
            //     this.getZoneList(admin.district_id);
            // }
            // this.getComboboxValues("PROFCON_COURSES");
            this.name = admin.name;
            this.mobile = admin.mobile;
            this.gander = admin.sex == 'Male' ? 'M' : 'F';
            this.unit = admin.unit_id;
            this.district = admin.district_id == 0 ? 'others' : admin.district_id;
            this.other_native_district = admin.other_native_district;
            this.zone = admin.zone_id;
            this.place = admin.place;
            this.course_type = admin.course_type_id;
            this.getComboboxValues("PROFCON_COURSES", admin.course_type);
            this.campus_district = admin.campus_district_id == 0 ? 'others' : admin.campus_district_id;
            this.getCollegeList();
            this.course = admin.course_id == 0 ? 'others' : admin.course_id;
            this.campus = admin.campus_id == 0 ? 'others' : admin.campus_id;

            this.other_college = admin.campus_other;
            this.other_course = admin.course_other;
            this.other_district = admin.campus_district_other;

            this.year_of_study = admin.year_of_study;
            this.year_of_completion = admin.year_of_completion;

            this.editable_id = admin.id;
            this.add_admin = false;
            this.edit_admin = true;
        },
        sendEditLink(admin) {
            let data = {
                reg_no: admin.reg_no
            }
            UserService.authPostRequest('send-edit-link', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                        this.message = '';
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        adminAdd() {
            $('#addAdminModel').modal('show');
            this.getZoneList(this.currentUser.district_id);
            this.getUnitList(this.currentUser.zone_id);
            this.disable_member_form = false;
            this.message = '';
            this.member_id = '';
            this.name = '';
            this.mobile = this.search_qry;
            this.subscription_no = '';
            this.receipt_no = '';
            this.member_edit = true;
            this.member = '';
            this.editable_id = null;
            this.add_admin = true;
            this.edit_admin = false;
            this.payment_status = 'Paid';
            this.unit = this.currentUser.unit_id;
        },
        closeAddAdminModel() {
            $('#addAdminModel').modal('hide');
        },
        addAdmin(user) {
            user.editable_id = this.editable_id;
            user.event_type = this.$route.params.slug;
            user.is_your_zone = this.isYourZonal;
            user.admin_level = this.currentAdminLevel;
            user.confirmation_status = 'Y';
            user.checkin_status = 'Y';
            user.payment_status = this.payment_status;
            user.checkin = true;
            if (this.currentAdminLevel == 7) {
                user.campus_district = this.campus_district;
                user.campus = this.campus;
            }
            UserService.addAdmin('store-event-registration', user).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#addAdminModel').modal('hide')
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.alert_type = 'Error';
                    this.alert_message = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.$refs.alert_model.openModel();
                    this.loading = false;

                }
            );
        },
        adminDeleteConfirmation(admin_list) {
            var data = {
                id: admin_list.id,
            }
            this.dialog_action_params = data;
            this.dialog_message = 'ശരിക്കും ഡിലീറ്റ് ചെയ്യണോ?';
            this.dialog_action = 'adminDelete';
            this.$refs.dialog_model.openModel();
            this.loading = false;
        },
        adminDelete(data) {
            UserService.authPostRequest('delete-event-registration', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList(this.member_lists.current_page, this.currentAdminLevel);
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getWing(wing) {
            if (wing == 1)
                return 'വിസ്‌ഡം';
            else if (wing == 2)
                return 'യൂത്ത്';
            else if (wing == 3)
                return 'സ്റ്റുഡന്റസ്';
            else if (wing == 4)
                return 'വിമെൻ';
            else
                return 'ഗേൾസ്';
        },
        displayAll() {
            this.search_qry = '';
            this.getMembersList();
        },
        getMembersList(page = 1, currentAdminLevel = null, filter = false) {
            this.page_type = 'search';
            this.loading_list = true;
            if (filter == 'campus') {
                this.filter_district = '';
                this.filter_zone = '';
                this.filter_unit = '';
            }
            if (filter == 'local') {
                this.filter_campus_district = '';
                this.filter_campus = '';
            }
            let data = {
                event: this.$route.params.slug,
                event_id : this.$route.params.event_id,
                type: 'registration',
                page_type: 'checkin',
                search_mode: this.search_mode,
                filter_district: this.filter_district ? this.filter_district : '',
                filter_campus_district: this.filter_campus_district ? this.filter_campus_district : '',
                filter_campus: this.filter_campus ? this.filter_campus : '',
                filter_zone: this.filter_zone ? this.filter_zone : '',
                filter_unit: this.filter_unit ? this.filter_unit : '',
                filter_reg_type: this.filter_reg_type ? this.filter_reg_type : '',
                filter_confirm: this.filter_confirm ? this.filter_confirm : '',
                filter_payment: this.filter_payment ? this.filter_payment : '',
                filter_sex: this.filter_sex ? this.filter_sex : '',
                search_qry: this.search_qry ? this.search_qry : '',
                admin_level: currentAdminLevel ? currentAdminLevel : this.currentAdminLevel
            }
            UserService.authPostRequest(`get-event-registrations?page=${page}`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.responseData = response.data.data;
                        this.permissions = response.data.data.permissions;
                        this.member_lists = response.data.data.list;
                        if (this.member_lists.total > 0) {
                            this.registrations_list = true;
                        }
                        this.search_qty = '';
                        this.orgnal_member_lists = response.data.data.list.data;
                        this.unit = response.data.data.unit;
                        if (this.responseData.questians) {
                            let count = 0;
                            this.responseData.questians.forEach(element => {
                                this.questians_responses[count] = element.answer_text;
                                count++;
                            });
                        }
                        if (this.responseData.campus) {
                            this.campus_district = this.responseData.campus.campus_district_id;
                            this.campus = this.responseData.campus.id;
                        }
                        this.$root.updateParent(this.responseData.event.event_name);
                        this.loading_list = false;
                    } else {
                        this.responseData = response.data.data;
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getCheckinList(page = 1, currentAdminLevel = null, filter = false) {
            this.page_type = 'checkin';
            this.checkin_lists = true;
            this.loading_list = true;
            if (filter == 'campus') {
                this.filter_district = '';
                this.filter_zone = '';
                this.filter_unit = '';
            }
            if (filter == 'local') {
                this.filter_campus_district = '';
                this.filter_campus = '';
            }
            let data = {
                event: this.$route.params.slug,
                event_id : this.$route.params.event_id,
                type: 'checkin',
                search_mode: this.search_mode,
                filter_district: this.filter_district ? this.filter_district : '',
                filter_campus_district: this.filter_campus_district ? this.filter_campus_district : '',
                filter_campus: this.filter_campus ? this.filter_campus : '',
                filter_zone: this.filter_zone ? this.filter_zone : '',
                filter_unit: this.filter_unit ? this.filter_unit : '',
                filter_reg_type: this.filter_reg_type ? this.filter_reg_type : '',
                filter_confirm: this.filter_confirm ? this.filter_confirm : '',
                filter_payment: this.filter_payment ? this.filter_payment : '',
                filter_sex: this.filter_sex ? this.filter_sex : '',
                search_qry: this.search_qry ? this.search_qry : '',
                admin_level: currentAdminLevel ? currentAdminLevel : this.currentAdminLevel,
                filter_professional: this.filter_professional ? this.filter_professional : '',
                checkin: true
            }
            UserService.authPostRequest(`get-event-registrations?page=${page}`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.responseData = response.data.data;
                        this.permissions = response.data.data.permissions;
                        this.checkin_lists = response.data.data.list;
                        if (this.checkin_lists.total > 0) {
                            this.registrations_list = true;
                        }
                        this.search_qty = '';
                        this.orgnal_checkin_lists = response.data.data.list.data;
                        this.unit = response.data.data.unit;
                        if (this.responseData.questians) {
                            let count = 0;
                            this.responseData.questians.forEach(element => {
                                this.questians_responses[count] = element.answer_text;
                                count++;
                            });
                        }
                        if (this.responseData.campus) {
                            this.campus_district = this.responseData.campus.campus_district_id;
                            this.campus = this.responseData.campus.id;
                        }
                        this.$root.updateParent(this.responseData.event.event_name);
                        this.loading_list = false;
                    } else {
                        this.responseData = response.data.data;
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        changeIsZonal(is_zonal) {
            if (is_zonal == 'Y') {
                this.getUnitList(this.currentUser.zone_id);
            } else {
                this.getDistrictList();
            }
        },
        getDistrictList() {
            // this.loading_list = true;
            let data = {}
            UserService.authPostRequest(`get-district-by-id`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.district_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getZoneList(district_id) {
            // this.loading_list = true;
            let data = {
                district_id: district_id
            }
            UserService.authPostRequest(`get-zone-by-id`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.zone_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getUnitList(zone_id) {
            // this.loading_list = true;
            let data = {
                zone_id: zone_id
            }
            UserService.authPostRequest(`get-unit-by-id`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.unit_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        changeCourseType(item) {
            this.getCollegeList();
            this.getComboboxValues("PROFCON_COURSES", item);
        },
        getCollegeList(district_id = null) {
            let data = {
                district_id: this.campus_district ? this.campus_district : '',
                stream_id: this.course_type ? this.course_type : '',
            }
            if (district_id) {
                data.district_id = district_id;
            }
            UserService.authPostRequest(`get-campus-list`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.campus_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        clearFilter() {
            this.filter_district = '';
            this.filter_zone = '';
            this.filter_unit = '';
            this.getMembersList();
        },
        confirmationView(admin) {
            this.editable_id = admin.id;
            this.confirmation_status = admin.confirmation_status;

            $('#confirmationModel').modal('show');
        },
        confirmation(data) {
            this.loading = true;
            data.id = this.editable_id;
            data.confirmation_status = this.confirmation_status;
            data.from = 'checkin';
            UserService.authPostRequest('confirmation-event-registration', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getCheckinList(this.member_lists.current_page, this.currentAdminLevel);
                        $('#confirmationModel').modal('hide');
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },

        checkinView(admin) {
            this.selected_candidate = admin;
            this.candidate_checkin = admin.checkin_status == "Y" ? true : false;
            this.spouse_checkin = admin.spouse_checkin_status == "Y" ? true : false;

            this.children = admin.children;
            this.childrens_mod = [];
            this.children.forEach(element => {
                if (element.checkin_status == 'Y') {
                    this.childrens_mod.push(element.id);
                }
            });

            this.candidate_name = admin.name;
            this.additional_participants = admin.additional_participation_checkin;
            this.spouse_name = admin.spouse_name;
            this.editable_id = admin.id;
            this.remarks = admin.checkin_remarks ? admin.checkin_remarks : '';
            // if (admin.checkin_status != null) {
            //     this.checkin_status = admin.checkin_status;
            // }
            // if (admin.spouse_checkin_status != null) {
            //     this.spouse_checkin_status = admin.spouse_checkin_status;
            // } else {
            //     this.spouse_checkin_status = '';
            // }
            // this.childrens_0_3_checkin = admin.childrens_0_3_checkin;
            // this.childrens_4_8_checkin = admin.childrens_4_8_checkin;
            // this.childrens_9_14_checkin = admin.childrens_9_14_checkin;
            if (admin.payment_status == 'Paid') {
                this.fees_update_disabled = true;
            } else {
                this.fees_update_disabled = false;
            }
            this.payment_status = admin.payment_status;
            $('#checkinModel').modal('show');
        },
        checkin(data) {
            this.loading = true;
            data.id = this.editable_id;
            data.checkin_status = this.checkin_status;




            data.spouse_checkin_status = this.spouse_checkin_status;
            data.payment_status = this.payment_status;

            data.candidate_checkin = this.candidate_checkin;
            data.spouse_checkin = this.spouse_checkin;
            data.child_checkin = this.child_checkins;
            UserService.authPostRequest('checkin-event-registration', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        if (this.page_type == 'search') {
                            this.getMembersList(this.member_lists.current_page, this.currentAdminLevel);
                        } else {
                            this.getCheckinList(this.member_lists.current_page, this.currentAdminLevel);
                        }
                        $('#checkinModel').modal('hide');
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        pageType(type) {
            if (type == 'checkin') {
                this.search_qry = '';
                this.page_type = 'search';
                this.member_lists = [];
            } else {
                this.search_qry = '';
                this.page_type = 'checkin';
                this.getCheckinList();
            }
        }
    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    /* margin-bottom: 20px; */
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.questians-label {
    font-weight: 600 !important;
    font-size: 14px !important;

}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.deleted-list-title {
    font-size: 15px;
}

.deleted-list {
    margin-bottom: 20px;
}

.deleted-info {
    background-color: #f2bbca;
    font-size: 12px;
}

.deleted-info strong {
    font-size: 18px;
    font-weight: 600;
}

.image-listview.text>li:after {
    left: 0px;
    /* height: 1px; */
    background-color: #1a418c;
}

.verification-badge {
    margin-left: 5px;
}

.avatar-section .button {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 24px;
    bottom: 0;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .payment-verified {
    background: #05d0a4;
}



.avatar-section .not-verified {
    background: #eb3941;
}

.wallet-card .wallet-footer .item .icon-wrapper {
    width: 100%;
    height: 48px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
    border-radius: 10px;
    color: #fff;
    /* font-size: 28px; */
    margin-bottom: 14px;
    padding-left: 10px;
}

.wallet-card {
    padding-bottom: 0px !important;

}

.menu-title {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    text-align: left !important;
}

.wallet-card .balance .title {
    color: #27173E;
    font-weight: 500;
    font-size: 16px;
    display: block;
    margin-bottom: 8px;
}

.stat-box {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 10px 10px;
}

.block {
    display: block;
}

.avatar-section .button-payment {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 5px;
    bottom: 0;
}</style>